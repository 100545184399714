<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card
          :title="$t('Users.Relate.title')"
        >
          <b-row
            class="d-flex flex-row"
          >
            <b-col
              cols="12"
              md="2"
              sm="4"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :label="$t('Users.Relate.perPage.label')"
                label-for="perPageSelect"
                :description="$t('Users.Relate.perPage.description')"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              sm="8"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Users.Relate.sort.description')"
                :label="$t('Users.Relate.sort.label')"
                label-for="sortBySelect"
              >
                <!-- TODO: Apply translation to this component-->
                <b-input-group>
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="align-items-center justify-content-end"
            >
              <b-form-group
                :description="$t('Users.Relate.filter.description')"
                :label="$t('Users.Relate.filter.label')"
                label-for="filterInput"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    :placeholder="$t('Users.Relate.filter.placeholder')"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      variant="outline-primary"
                      @click="filter = ''"
                    >
                      {{ $t('Users.Relate.filter.buttonText') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(role)="data">
                  <b-badge :variant="status[0][data.value.toLowerCase()]">
                    {{ $t(`Users.roles.${data.value.toLowerCase()}`) }}
                  </b-badge>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="status[0][data.value.toLowerCase()]">
                    {{ $t(`Users.statuses.${data.value.toLowerCase()}`) }}
                  </b-badge>
                </template>

                <template #cell(action)="row">
                  <b-button
                    v-b-modal.modal-candidates
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="showCandidatesModal(row.item)"
                  >
                    <feather-icon icon="Link2Icon" />
                  </b-button>
                </template>

              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- Modal for Relationship candidates BEGIN -->
      <b-modal
        id="modal-candidates"
        size="xl"
        centered
        :title="$t('Users.Relate.modal.title')"
        @ok="submitRelations"
      >
        <b-form-group
          :description="$t('Users.Relate.filter.description')"
          :label="$t('Users.Relate.filter.label')"
          label-for="filterInputModal"
        >
          <b-input-group>
            <b-form-input
              id="filterInputModal"
              v-model="candidates.filter"
              type="search"
              :placeholder="$t('Users.Relate.filter.placeholder')"
            />
            <b-input-group-append>
              <b-button
                :disabled="!candidates.filter"
                variant="outline-primary"
                @click="candidates.filter = ''"
              >
                {{ $t('Users.Relate.filter.buttonText') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-table
          :fields="candidates.fields"
          :items="candidates.items"
          :filter="candidates.filter"
          responsive
          hover
          @row-clicked="rowClicked"
        >
          <template #cell(status)="row">
            <b-badge :variant="status[0][row.value.toLowerCase()]">
              {{ $t(`Users.statuses.${row.value.toLowerCase()}`) }}
            </b-badge>
          </template>
          <template v-slot:cell(related)="row">
            <div class="d-flex">
              <b-form-checkbox
                v-model="row.item.related"
                class="mx-auto"
              />
            </div>
          </template>
        </b-table>
        <template #modal-footer="{ ok }">
          <!-- Deselect all -->
          <b-button
            variant="outline-secondary"
            @click="setAllCandidateRelationshipsAs(false)"
          >
            {{ $t('Users.Relate.modal.buttonTexts.deselectAll') }}
          </b-button>
          <!-- Select all -->
          <b-button
            variant="outline-secondary"
            @click="setAllCandidateRelationshipsAs(true)"
          >
            {{ $t('Users.Relate.modal.buttonTexts.selectAll') }}
          </b-button>
          <!-- Ok -> Submit -->
          <b-button
            class="ml-auto"
            variant="outline-primary"
            @click="ok()"
          >
            {{ $t('Users.Relate.modal.buttonTexts.save') }}
          </b-button>
        </template>
      </b-modal>
      <!-- Modal for Relationship candidates END -->
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: this.$i18n.t('Users.Relate.table.fieldLabels.id') },
        { key: 'email', label: this.$i18n.t('Users.Relate.table.fieldLabels.email') },
        { key: 'role', label: this.$i18n.t('Users.Relate.table.fieldLabels.role') },
        { key: 'status', label: this.$i18n.t('Users.Relate.table.fieldLabels.status') },
        { key: 'action', label: this.$i18n.t('Users.Relate.table.fieldLabels.action') },
      ],
      items: [],
      status: [
        {
          administrator: 'primary',
          overseer: 'secondary',
          client: 'info',
          external: 'danger',
          collector: 'warning',
          active: 'light-success',
          transition: 'light-info',
          inactive: 'light-danger',
          // 2: 'light-info',
          // 3: 'success',
          // 4: 'warning',
          // 5: 'light-primary',
          // 6: 'dark',
        },
      ],
      currentSourceID: -1,
      currentRole: 4, // Overseer
      currentRelType: 0, // Oversee
      candidates: {
        filter: null,
        items: [],
        fields: [
          { key: 'id', label: this.$i18n.t('Users.Relate.table.fieldLabels.id') },
          { key: 'first_name', label: this.$i18n.t('Users.Relate.table.fieldLabels.firstName') },
          { key: 'last_name', label: this.$i18n.t('Users.Relate.table.fieldLabels.lastName') },
          { key: 'email', label: this.$i18n.t('Users.Relate.table.fieldLabels.email') },
          { key: 'status', label: this.$i18n.t('Users.Relate.table.fieldLabels.status') },
          { key: 'related', label: this.$i18n.t('Users.Relate.table.fieldLabels.related'), class: 'text-center' },
        ],
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.$http.get('/admin/users/list-by-role', {
      params: {
        role: this.currentRole, // DONE: Make this a parameter
      },
    })
      .then(response => {
        this.items = response.data.entries
        this.totalRows = this.items.length
      })
      .catch(error => {
        console.log('There was an error:', error)
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showCandidatesModal(item) {
      // console.log(item.id)
      this.currentSourceID = item.id
      this.$http.get('/admin/users/list-relationship-candidates', {
        params: {
          userID: this.currentSourceID,
          relationshipType: this.currentRelType, // DONE: Make this a parameter
        },
      })
        .then(response => {
          // console.log(response.data)
          this.candidates.items = response.data.entries
        })
        .catch(error => {
          console.log(error)
        })
    },
    submitRelations() {
      const params = {
        source: this.currentSourceID,
        type: this.currentRelType,
        rels: this.candidates.items.map(v => ({ target: v.id, relate: v.related })),
      }
      this.$http.post('/admin/users/set-relationships', params)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: this.$i18n.t('SwAl.titles.success'),
            html: this.$i18n.t('SwAl.htmls.relationshipsUpdated'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: this.$i18n.t('SwAl.titles.error'),
            html: this.$i18n.t('SwAl.htmls.couldntUpdateRelationships'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        })
    },
    setAllCandidateRelationshipsAs(value) {
      // A not convoluted at all (?)
      // way to edit the array
      this.candidates.items.forEach((_, index) => {
        this.candidates.items[index].related = value
      }, this)
    },
    rowClicked(item, index) {
      this.candidates.items[index].related = !item.related
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
